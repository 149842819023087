/**
 * Prints a log statement to the console if the DEBUG flag is true.
 */

export function log(...args: any[]): void {
  if (isFCILoggingEnabled()) {
    // eslint-disable-next-line no-console
    console.log('[fci]', ...args);
  }
}

function isFCILoggingEnabled(): boolean {
  try {
    if ('sessionStorage' in window && window.sessionStorage) {
      const debugStr = window.sessionStorage.enableFCILogging;
      const debug = debugStr && debugStr.toLowerCase() === 'true';
      return debug && typeof console !== 'undefined' && !!console;
    }
  } catch (e) {
    // sessionStorage errors
  }

  return false;
}
