import { type IFCIMeasurementOptions, FCIMeasurer, type IFCIResult, type ILongTask } from './FCI';

export type { IFCIMeasurementOptions, IFCIResult, ILongTask };
/**
 * Returns a promise that resolves to the first CPU Idle time
 * (in milliseconds) or null if the browser doesn't support the features
 * required for detection.
 * @public
 */
export function getFirstCPUIdle(options: IFCIMeasurementOptions): Promise<IFCIResult | null> {
  if ('PerformanceLongTaskTiming' in window) {
    const detector = new FCIMeasurer(options);
    return detector.measureFCI();
  } else {
    return Promise.resolve(null);
  }
}
