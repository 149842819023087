import { log } from './Log';

/**
 * @public
 */
export interface IFCIMeasurementOptions {
  requiredMainThreadCPUIdleDurationInMilliseconds: number;
  measurementStartTime: number;
  measureName?: string;
  /**
   * The initial performance entries to use for the calculation that happened before measurement started,
   */
  initialEntries?: PerformanceEntry[];
}

/**
 * @public
 */
export interface ILongTask {
  start: number;
  end: number;
  id: number;
  endMarkName?: string;
  [key: string]: unknown;
}

/**
 * FCI result
 * @public
 */
export interface IFCIResult {
  fci: number;
  longTasks: ILongTask[];
}

declare const window: Window & {
  __fci: {
    e: PerformanceEntry[];
    o: PerformanceObserver;
  };
  chrome?: {
    loadTimes?: () => {
      firstPaintTime: number;
    };
  };
};

function mark(name: string): void {
  if (window.performance && performance.mark) {
    performance.mark(name);
  }
}

export class FCIMeasurer {
  private _requiredMainThreadCPUIdleDurationInMilliseconds: number;
  private _measurementStartTime: number;
  private readonly _measureName: string;
  private _longTaskObserver: PerformanceObserver;
  private _resultResolver: {
    resolve: ((result: IFCIResult) => void) | undefined;
    reject: ((err: Error) => void) | undefined;
  };
  private _fciPromise: Promise<IFCIResult> | undefined;
  private _longTasks: ILongTask[];

  private _longTaskId: number = 0;
  private _checkFCIRunId: number = 0;
  private _isDisposed: boolean = false;

  constructor(options: IFCIMeasurementOptions) {
    const { requiredMainThreadCPUIdleDurationInMilliseconds, measurementStartTime } = options;
    this._measureName = options.measureName || 'FCI';
    this._measurementStartTime = measurementStartTime;
    this._requiredMainThreadCPUIdleDurationInMilliseconds = requiredMainThreadCPUIdleDurationInMilliseconds;
    this._resultResolver = {
      resolve: undefined,
      reject: undefined
    };
    this._fciPromise = undefined;
    this._longTasks = [];

    this._processLongTaskPreQueue(options.initialEntries);
    this._registerLongTaskObserver();
  }

  /**
   * Measure First CPU idle
   */
  public measureFCI(): Promise<IFCIResult> {
    if (this._isDisposed) {
      throw 'FCIMeasurer is disposed.';
    }
    if (!this._fciPromise) {
      this._fciPromise = new Promise<IFCIResult>(
        (resolve: (data: IFCIResult) => void, reject: (err: Error) => void) => {
          this._resultResolver.resolve = resolve;
          this._resultResolver.reject = reject;
        }
      );
    }
    this._tryScheduleNextCheck();
    return this._fciPromise;
  }

  private _tryScheduleNextCheck(): void {
    if (this._checkFCIRunId) {
      // clear previously schduled run if it has not been done yet
      clearTimeout(this._checkFCIRunId);
    }
    const earliestPossibleCheckTime = this._findEarlestPossibleCheckTime();
    const now = performance.now();
    const nextCheckTime = Math.max(0, earliestPossibleCheckTime - now);
    this._checkFCIRunId = setTimeout(() => {
      this._checkFCI();
    }, nextCheckTime);
  }

  private _findEarlestPossibleCheckTime(): number {
    const latestLongTask = this._getLatestLongTask();
    const latestLongTaskEndTime = (latestLongTask && latestLongTask.end) || -Infinity;
    // We want 1 second past the last long task, 1 second past the start of the request
    // or now - whichever is greatest.
    // An extra millisecond is added to compensate for the rounding that can happen in setTimeout
    return (
      Math.max(
        latestLongTaskEndTime + this._requiredMainThreadCPUIdleDurationInMilliseconds,
        this._measurementStartTime + this._requiredMainThreadCPUIdleDurationInMilliseconds,
        performance.now()
      ) + 1
    );
  }

  private _checkFCI(): void {
    const currentTime = performance.now();
    const ltLength: number = this._longTasks ? this._longTasks.length : 0;

    log(`Running checkFCI:`);
    log(`Measurement start time`, this._measurementStartTime);
    log(`Current time`, currentTime);
    log(`Long tasks`, this._longTasks);
    log(`latestLongTaskEndTime`, this._longTasks[ltLength - 1]?.end);

    if (!this._measurementStartTime || this._measurementStartTime < 0) {
      this._onFoundFCI(NaN);
      return;
    }

    if (ltLength === 0) {
      if (currentTime - this._measurementStartTime >= this._requiredMainThreadCPUIdleDurationInMilliseconds) {
        // If there are no long tasks and the required time has passed since measurement start (EUPL), then set FCI to EUPL
        this._onFoundFCI(this._measurementStartTime);
      }
      return;
    }

    let prevTask: ILongTask = this._longTasks[0];
    for (let i = 1; i < ltLength; i++) {
      const task: ILongTask = this._longTasks[i];
      const compareTime: number = Math.max(prevTask.end, this._measurementStartTime);
      if (
        task.start >= this._measurementStartTime &&
        task.start - compareTime >= this._requiredMainThreadCPUIdleDurationInMilliseconds
      ) {
        this._onFoundFCI(compareTime, prevTask);
        return;
      }
      prevTask = task;
    }

    if (currentTime - prevTask.end >= this._requiredMainThreadCPUIdleDurationInMilliseconds) {
      const task: ILongTask | undefined = prevTask.end > this._measurementStartTime ? prevTask : undefined;
      this._onFoundFCI(Math.max(prevTask.end, this._measurementStartTime), task);
    }
  }

  private _onFoundFCI(fci: number, longTask?: ILongTask): void {
    log(`marking FCI measure:`, longTask);
    try {
      // Add a performance measure for FCI so it shows up in the performance profile
      if (longTask?.endMarkName) {
        performance.measure(this._measureName, undefined, longTask.endMarkName);
      } else {
        // This is the version that we want to use, but it is not available in all browsers
        // https://developer.mozilla.org/en-US/docs/Web/API/Performance/measure
        performance.measure(this._measureName, { end: fci });
      }
    } catch (error) {
      // ignore error so that the FCI value can still be returned
      log(`failed to performance.measure('FCI'):`, error);
    }

    log(`possibleFCI:`, fci);
    this._resultResolver.resolve &&
      this._resultResolver.resolve({
        fci: fci,
        longTasks: this._longTasks
      });
    this._dispose();
  }

  private _dispose(): void {
    clearTimeout(this._checkFCIRunId);
    this._longTaskObserver.disconnect();
    this._isDisposed = true;
  }

  private _getLatestLongTask(): ILongTask | undefined {
    let ret = undefined;
    for (const task of this._longTasks) {
      if (!ret || task.end > ret.end) {
        ret = task;
      }
    }
    return ret;
  }

  private _processLongTaskPreQueue(initialEntries?: PerformanceEntry[]): void {
    if (initialEntries) {
      for (const entry of initialEntries) {
        if (entry.entryType === 'longtask') {
          const longtask = this._createLongTask(entry);
          this._longTasks.push(longtask);
        }
      }
    } else {
      if (!window.__fci) {
        return;
      }
      const observer = window.__fci.o;
      const entries = window.__fci.e;
      if (observer) {
        for (const entry of entries) {
          if (entry.entryType === 'longtask') {
            const longtask = this._createLongTask(entry);
            this._longTasks.push(longtask);
          }
        }
        observer.disconnect();
      }
    }
  }

  private _registerLongTaskObserver(): void {
    this._longTaskObserver = new PerformanceObserver((entryList: PerformanceObserverEntryList) => {
      const entries = entryList.getEntries();
      for (const entry of entries) {
        if (entry.entryType === 'longtask') {
          this._longTaskFinishedCallback(entry);
        }
      }
    });
    this._longTaskObserver.observe({ entryTypes: ['longtask'] });
  }

  private _createLongTask(performanceEntry: PerformanceEntry): ILongTask {
    const taskEndTime = performanceEntry.startTime + performanceEntry.duration;
    const task: ILongTask = {
      attribution: (performanceEntry as any).attribution,
      name: performanceEntry.name,
      start: Math.round(performanceEntry.startTime),
      end: Math.round(taskEndTime),
      id: this._longTaskId++
    };
    return task;
  }

  private _longTaskFinishedCallback(performanceEntry: PerformanceEntry): void {
    log(`Long task finished: `, performanceEntry);
    const task = this._createLongTask(performanceEntry);
    const markName = `longTaskEnd${task.id}`;
    mark(markName);
    task.endMarkName = markName;
    this._longTasks.push(task);
    this._tryScheduleNextCheck();
  }
}
